import React from 'react';
import { useState } from 'react';

const IntroModal = ({ onCloseModal, personName }) => {

    const [isActive, setIsActive] = useState(true);

    const closeModal = () => {
        onCloseModal()
        setIsActive(false)
    }
    return (
        <div className={"modal " + (isActive ? "is-active" : "")}>
            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
            <div className="modal-background" onClick={() => closeModal()} role="dialog"/>
            <div className="modal-card">
                <header className="modal-card-head is-justify-content-space-between">
                    <span>Hello, {personName}!</span>
                    { <button className="delete" aria-label="close" onClick={() => closeModal()}></button>}
                </header>
                <section className="modal-card-body">
                    <p className="intro-modal__body-text">
                    Someone created this party for you to cut your cake virtually ❤<br/>
                    You can also share screen with your friends and let the cake cutting begin!<br/>
                    </p>
                </section>
                <footer className="modal-card-foot is-justify-content-center">
                    <button className="button intro-modal__cta-btn is-medium is-primary" onClick={() => closeModal()}>Let's start</button>
                </footer>
            </div>
        </div>
    )
}

export default IntroModal;
