export const DEFAULT_PERSON_NAME = 'Alex';

export const DEFAULT_EVENT_TYPE = 'Birthday';

export const DEFAULT_PARTY_MESSAGES = [
  {
    from: 'Harry',
    msg: `Hey friend, Happy Birthday! Have a blast! 🎉🎉🎉`
  },
  {
    from: 'Ron',
    msg: `Happy Birthday! Save some of that cake for me, will you? 🤤🤤🤤`
  },
  {
    from: 'Ginny',
    msg: `Happy Birthday! Here's to many more! 🥂🥂🥂`
  },
  {
    from: 'Snape',
    msg: `Get back to class!!! 😡😤😤`
  }
]