import initCanvasDimensions, { getCanvasWidth, getCanvasHeight, getScaleX, getScaleXBounded, getScaleY } from "../../utils/dimensions";
import sceneStates from "../../utils/SceneState";

import Background from '../shapes/Background';
import Cake from "../shapes/Cake";
import Knife from "../shapes/Knife";
import Candle from '../shapes/Candle'
import CandleBlower from "../shapes/CandleBlower";
import ArrowStrip from "../shapes/ArrowStrip";
import Text from "../shapes/Text";
import { sendCustomEvent, EventLabels, EventNames } from "../../utils/analytics";
import { resetDrag } from "../../utils/mouseCoords";
import { calculateDistance, convertDegreeToRadians } from "../../utils/helpers";
import initAudio from "../../utils/audio";
import birthdaySound from '../../../static/assets/cheering.mp3';

export default (event_type, person_name) => {
  const background = new Background();
  
  const backCakeSlice = new Cake(
    getCanvasWidth()/2,
    getCanvasHeight()/2,
    convertDegreeToRadians(180),
    0,
  );

  const frontCakeSlice = new Cake(
    getCanvasWidth()/2,
    getCanvasHeight()/2,
    0,
    convertDegreeToRadians(180),
  );

  const blowCandleText1 = new Text({
    text: "Blow the candles",
    y: getCanvasHeight()*0.73,
    width: 500,
    alpha: 1,
    font: 'Nunito',
    fontSize: getCanvasWidth() > 400 ? 48 : 24,
    color: '#132743',
  });
  const blowCandleText2 = new Text({
    text: "by moving the blower near each candle",
    y: getCanvasHeight()*0.73 + (getCanvasWidth() > 400 ? 48 : 24) + 8,
    width: 500,
    alpha: 1,
    font: 'Nunito',
    fontSize: getCanvasWidth() > 400 ? 24 : 16,
    color: '#132743',
  });
  const cakeCutText = new Text({
    text: "Cut the cake",
    y: getCanvasHeight()*0.8,
    width: 300,
    alpha: 1,
    font: 'Nunito',
    fontSize: 32,
    color: '#132743',
  });
  
  const birthdayWishText = new Text({
    y: getCanvasHeight()*0.1,
    alpha: 0,
    width: 200,
    font: 'Fugaz One',
    fontSize: getCanvasWidth() > 400 ? 48 : 36,
    color: '#ff0054',
    shadowBlur: 5,
  });

  const candles = [
    new Candle(getCanvasWidth()/2 + 80 * getScaleXBounded(), getCanvasHeight()/2 - 40 * getScaleY(), '#f25f5c'),
    new Candle(getCanvasWidth()/2 - 80 * getScaleXBounded(), getCanvasHeight()/2 - 40 * getScaleY(), '#ffe066'),
    new Candle(getCanvasWidth()/2, getCanvasHeight()/2 + 60 * getScaleY(), '#b9e769'),
  ];

  const fan = new CandleBlower(getCanvasWidth()/4, getCanvasHeight()/4);

  const knife = new Knife(getCanvasWidth()/2 + 150 * getScaleXBounded(0.5), getCanvasHeight()/2, 80 * getScaleY());
  const arrowStrip = new ArrowStrip(getCanvasWidth()/2 + 400 * getScaleX(), getCanvasHeight()/2 + 100 * getScaleY());


  function draw(event_type, person_name, messages, sceneState, setSceneState) {
    const birthdayRawText = `Happy ${event_type} ${person_name}!`;
    background.draw();

    switch(sceneState) {
      case sceneStates.INIT: {
        backCakeSlice.draw();
        frontCakeSlice.draw();
        candles.forEach(candle => candle.draw());
        break;
      }

      case sceneStates.CANDLE_BLOWING: {
        blowCandleText1.draw();
        blowCandleText2.draw();
        backCakeSlice.draw();
        frontCakeSlice.draw();
        candles.forEach(candle => candle.draw());
        fan.draw();
        break;
      }

      case sceneStates.SHOW_MESSAGES:
      case sceneStates.CAKE_CUTTING: {
        cakeCutText.draw();
        backCakeSlice.draw();
        candles.slice(0, 2).forEach(candle => candle.draw());
        knife.draw();
        frontCakeSlice.draw();
        candles.slice(2).forEach(candle => candle.draw());
        !knife.showPointer && arrowStrip.draw();
        birthdayWishText.draw(birthdayRawText);
        break;
      }

      default: {
        backCakeSlice.draw();
        frontCakeSlice.draw();
        break;
      }
    }
  }

  function update(event_type, person_name, messages, sceneState, setSceneState) {

    switch(sceneState) {
      case sceneStates.CANDLE_BLOWING: {
        candles.forEach(candle => {
          candle.update();
        });
        fan.update();
        blowCandleText1.update();
        blowCandleText2.update();

        candles.forEach(candle => {
          if(candle.state === 'burning' && calculateDistance(candle.getPosition(), fan.getPosition()) < 40) {
            candle.extinguish();
            sendCustomEvent(EventLabels.CAKE, EventNames.CANDLE_BLOWN, { id: candle.id });
            blowCandleText1.hide();
            blowCandleText2.hide();
          }
        });

        if(candles.every(candle => candle.getState() === 'extinguished')) {
          setSceneState(sceneStates.CAKE_CUTTING);
          resetDrag();
        }
        break;
      }

      case sceneStates.SHOW_MESSAGES:
      case sceneStates.CAKE_CUTTING: {
        knife.update();
        arrowStrip.update();
        cakeCutText.update();
        birthdayWishText.update();
        const { y } = knife.getPosition();
        if(knife.isBeingDragged && cakeCutText.alpha === 1) {
          cakeCutText.hide();
        }
        if(knife.animate && (y > knife.initY + knife.lowBound - 2)) {
          knife.stopAnimating();
          sendCustomEvent(EventLabels.CAKE, EventNames.CAKE_CUT);
          arrowStrip.fillAll();
          birthdayWishText.show(0.05);
          initAudio(birthdaySound);
          setSceneState(sceneStates.SHOW_MESSAGES);
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  let requestId = null;

  function cycle(event_type, person_name, messages, sceneState, setSceneState) {
    requestId = requestAnimationFrame(() => cycle(event_type, person_name, messages, sceneState, setSceneState));
    update(event_type, person_name, messages, sceneState, setSceneState);
    draw(event_type, person_name, messages, sceneState, setSceneState);
  }

  return function loop(event_type, person_name, messages, sceneState, setSceneState) {
    if(requestId) {
      cancelAnimationFrame(requestId);
    }
    cycle(event_type, person_name, messages, sceneState, setSceneState);
  }
}