import Shape from "./Shape";
import canvasText from 'canvas-txt';
import { getCanvasWidth } from "../../utils/dimensions";

export default class Text extends Shape {
  constructor(
    { x, y, font, fontSize, lineHeight, alpha, color, shadowBlur, height, width }
  ) {
    super();
    this.x = x || 0;
    this.y = y || 0;
    this.width = getCanvasWidth();
    this.height = height || 32;
    this.alpha = alpha || 0;
    this.alphaSpeed = 0;
    this.font = font || 'serif';
    this.fontSize = fontSize || 48;
    this.lineHeight = lineHeight || fontSize;
    this.color = color || '#333333';
    this.shadowBlur = shadowBlur;
  }

  show(speed = 0.1) {
    this.alphaSpeed = speed;
  }

  hide(speed = 0.1) {
    this.alphaSpeed = -speed;
  }

  update() {
    this.alpha += this.alphaSpeed;
    if(this.alpha >= 1 && this.alphaSpeed > 0) {
      this.alpha = 1;
      this.alphaSpeed = 0;
    }

    if(this.alpha <= 0 && this.alphaSpeed < 0) {
      this.alpha = 0;
      this.alphaSpeed = 0;
    }
  }

  draw(text="") {
    const { 
      ctx, x, y, width, height, alpha, font, fontSize, lineHeight
    } = this;

    ctx.save();
    ctx.globalAlpha = alpha;
    ctx.fillStyle = this.color;
    ctx.shadowBlur = this.shadowBlur;
    ctx.shadowColor = this.color;
    canvasText.font = font;
    canvasText.fontSize = fontSize;
    canvasText.lineHeight = lineHeight;
    canvasText.align = 'center';
    canvasText.vAlign = 'top';
    canvasText.drawText(ctx, text, x, y, width, height);
    // ctx.fillRect(0, 0, 100, 100);
    // ctx.fillText(text, 0, 0);
    ctx.restore();
  }
}