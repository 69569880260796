import React, { useCallback, useEffect, useState } from 'react'

import Layout from '../components/Layout'
import Canvas from '../components/canvas';
import birthdaySound from '../../static/assets/birthday.mp3';
import IntroModal from '../components/IntroModal';
import { getParty, getPartyMessages } from '../repository/party'
import FeedbackModal from '../components/feedback/modal';
import FloatingActionButton, { iconTypes } from '../components/FAB';
import { push } from 'gatsby-link';
import { EventLabels, EventNames, sendClickEvent } from '../utils/analytics';
import MessageCollectModal from '../components/MessageCollectModal';
import { DEFAULT_EVENT_TYPE, DEFAULT_PARTY_MESSAGES, DEFAULT_PERSON_NAME } from '../constants/defaultPartyData';

const PartyPage = ({ data }) => {

  const [playBgMusic, setPlayBgMusic] = useState(false);
  const [isBgMusicEnabled, setIsBgMusicEnabled] = useState(true);
  const [personName, setPersonName] = useState("You");
  const [eventType, setEventType] = useState("Birthday");
  const [isFeedbackModalShown, setIsFeedbackModalShown] = useState(false);
  const [isBirthdayPerson, setIsBirthdayPerson] = useState(false);
  const [partyMessages, setPartyMessages] = useState([]);
  const [partyId, setPartyId] = useState(null);

  useEffect(() => {
    async function getPartyData() {
      const party_match = (window.location.pathname || "").match(/party\/(\w*)/)
      const party_id = party_match && party_match[1]

      const code_match = (window.location.href || "").match(/code\=(\d*)/)
      const party_code = code_match && parseInt(code_match[1])
  
      if (party_id) {
          const partyData = await getParty(party_id)
          const messagesData = await getPartyMessages(party_id, party_code)
          console.log(partyData)
          // console.log(messagesData)
          const { event_type, person_name, version } = partyData
          const { messages } = messagesData
          setPartyId(party_id)
          person_name && setPersonName(person_name)
          event_type && setEventType(event_type)
          if ((!version || version < 2) || party_code) setIsBirthdayPerson(true)
          messages && messages.length && setPartyMessages(messages)
      } else {
        // Demo Party
        setPersonName(DEFAULT_PERSON_NAME)
        setEventType(DEFAULT_EVENT_TYPE)
        setIsBirthdayPerson(true)
        setPartyMessages(DEFAULT_PARTY_MESSAGES)
      }
    }
    getPartyData()
  }, []);

  const toggleBgMusic = useCallback(() => {
    setIsBgMusicEnabled(!isBgMusicEnabled);
  }, [isBgMusicEnabled]);

  return (
    <Layout>
      <div>
          <Canvas
            eventType={eventType}
            personName={personName}
            messages={partyMessages}
          />
          {isBirthdayPerson && <IntroModal onCloseModal={() => {setPlayBgMusic(true)}} personName={personName}/>}
          {!isBirthdayPerson && <MessageCollectModal onCloseModal={() => {setPlayBgMusic(true)}} personName={personName} partyId={partyId}/>}
          <FeedbackModal
              isModalActive={isFeedbackModalShown}
              onClose={() => setIsFeedbackModalShown(false)}
          />
          {
            playBgMusic ? (
            /* eslint-disable-next-line jsx-a11y/media-has-caption */
              <audio autoPlay muted={!isBgMusicEnabled}>
                <source src={birthdaySound}  type="audio/mp3" />
              </audio>
            ) : null
          }
          <FloatingActionButton
            iconType={iconTypes.HOME}
            onClick={() => {
              sendClickEvent(EventLabels.FAB, EventNames.HOME_CLICKED)
              push('/')
            }}
            corner={'top-left'}
          />
          <FloatingActionButton
            iconType={isBgMusicEnabled ? iconTypes.SOUND_ON : iconTypes.SOUND_OFF}
            onClick={() => {
              sendClickEvent(EventLabels.FAB, EventNames.TOGGLE_SOUND_CLICKED)
              toggleBgMusic()
            }}
            corner={'bottom-right'}
          />
          <FloatingActionButton
            iconType={iconTypes.FEEDBACK}
            onClick={() => {
              sendClickEvent(EventLabels.FAB, EventNames.FEEDBACK_CLICKED)
              setIsFeedbackModalShown(true)
            }}
            corner={'bottom-left'}
          />
      </div>
    </Layout>
  )
}

export default PartyPage;
