import { isFunction } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import initCanvasDimensions from "../../utils/dimensions";
import sceneStates from "../../utils/SceneState";
import initRenderLoop from './renderLoop';
import MessageNote from "./messageNote";

const settings = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default ({eventType, personName, messages}) => {
  console.log(messages);
  // console.log(eventType, personName);
  const loop = useRef();
  const [canvasRef, setCanvasRef] = useState(null);
  const [sceneState, setSceneState] = useState(sceneStates.CANDLE_BLOWING);
  const [isMessagesShown, setIsMessagesShown] = useState(false);
  useEffect(() => {
    if(canvasRef) {
      initCanvasDimensions(canvasRef);
      loop.current = initRenderLoop(eventType, personName, messages, sceneState, setSceneState);
      loop.current(eventType, personName, messages, sceneState, setSceneState);
    }
  }, [canvasRef]);

  useEffect(() => {
    console.log(`sceneState: ${sceneState}`)
    if(loop.current && isFunction(loop.current))
    loop.current(eventType, personName, messages, sceneState, setSceneState);
  }, [sceneState, eventType, personName, messages]);
  const showMessagesButton = !!(messages && messages.length && (sceneState === sceneStates.SHOW_MESSAGES));
  return (
    <>
      <canvas ref={ref => setCanvasRef(ref)} />
      
      {
        (isMessagesShown) && (
          <div className="messages-slider">
            <div className="messages-slider__container">
              <Slider {...settings}>
                {
                  messages.map((message, idx) => (
                    <MessageNote message={message} key={idx}/>
                  ))
                }
              </Slider>
            </div>
          </div>
        )
      }
      {
        (
          <button className={`button btn-messages ${showMessagesButton ? 'btn-messages--active': ''}`} onClick={() => setIsMessagesShown(!isMessagesShown)}>
            <span className="btn-messages__text">
              {`${!isMessagesShown ? 'View' : 'Hide'} wishes 🎉`}
            </span>
          </button>
        )
      }
    </>
  );
}