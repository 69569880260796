// Scene States

const INIT = 'INIT';
const CANDLE_BLOWING = 'CANDLE_BLOWING';
const CAKE_CUTTING = 'CAKE_CUTTING';
const SHOW_MESSAGES = 'SHOW_MESSAGES'; 

const sceneStates = {
  INIT,
  CANDLE_BLOWING,
  CAKE_CUTTING,
  SHOW_MESSAGES
};

export default sceneStates;