import { navigate, push } from 'gatsby-link';
import React from 'react';
import { useState } from 'react';
import { addMessage } from '../repository/party';
import { EventLabels, EventNames, sendCustomEvent } from '../utils/analytics';

const MessageCollectModal = ({ onCloseModal, personName, partyId }) => {

    const [isActive, setIsActive] = useState(true);
    const [message, setMessage] = useState('');
    const [messageFrom, setMessageFrom] = useState('');
    const [isMessageSubmitting, setIsMessageSubmitting] = useState(false);
    const [isMessageSubmitSuccess, setIsMessageSubmitSuccess] = useState(false);
    const [isMessageSubmitError, setIsMessageSubmitError] = useState(false);

    const closeModal = () => {
        onCloseModal()
        setIsActive(false)
    }

    const redirectToHomePage = () => {
        setTimeout(() => {
            push('/')
        }, 3000);
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        setIsMessageSubmitting(true)
        sendCustomEvent(EventLabels.Event, EventNames.SEND_BDAY_MESSAGE_CLICKED)
        addMessage(partyId, {
            msg: message,
            from: messageFrom
        }).then(() => {
            sendCustomEvent(EventLabels.Event, EventNames.SEND_BDAY_MESSAGE_CLICKED_SUCCESS)
            setIsMessageSubmitError(false)
            setIsMessageSubmitSuccess(true)
            setMessage('')
            setMessageFrom('')
            redirectToHomePage()
        }).catch(() => {
            sendCustomEvent(EventLabels.Event, EventNames.SEND_BDAY_MESSAGE_CLICKED_ERRORED)
            setIsMessageSubmitError(true)
        }).finally(() => {
            setIsMessageSubmitting(false)
        })
    }

    return (
        <div className={"modal " + (isActive ? "is-active" : "")}>
            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
            <div className="modal-background" onClick={() => closeModal()} role="dialog"/>
            <div className="modal-card">
                <header className="modal-card-head is-justify-content-space-between">
                    <span>Welcome to {personName}'s party!</span>
                </header>
                <section className="modal-card-body">
                    <p className="intro-modal__body-text mb-3">
                        Please send your birthday wish to {personName}<br/>
                        All messages will be collected and shown to the birthday person!<br/>
                    </p>
                    <form className="form-horizontal"
                        onSubmit={e => onFormSubmit(e)}
                        >
                            <fieldset>
                                <div className="field">
                                    <label className="label" htmlFor="birthday-message">Message</label>
                                    <div className="control">
                                        <textarea
                                            id="birthday-message"
                                            name="birthday-message"
                                            type="text"
                                            placeholder="Wishing a very happy birthday to my bestest friend!"
                                            className="input"
                                            value={message}
                                            required={true}
                                            maxLength={100}
                                            onChange={e => setMessage(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor="message-from">From</label>
                                    <div className="control">
                                        <input
                                            id="message-from"
                                            name="message-from"
                                            type="text"
                                            placeholder="Your tiffin buddy"
                                            className="input"
                                            value={messageFrom}
                                            required={true}
                                            maxLength={20}
                                            onChange={e => setMessageFrom(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <button type="submit" className={"button intro-modal__cta-btn is-medium is-primary" + (isMessageSubmitting ? " is-loading": "")}>Submit</button>
                                </div>
                                { isMessageSubmitError && <div className="field has-text-danger">{'Something went wrong, please try again!'}</div> }
                                { isMessageSubmitSuccess && <div className="field has-text-success">{'Success, redirecting to home page...'}</div> }
                            </fieldset>
                    </form>
                    
                </section>
                <footer className="modal-card-foot is-justify-content-center">
                    
                </footer>
            </div>
        </div>
    )
}

export default MessageCollectModal;
