import React from 'react';

function MessageNote({message}) {
  return (
    <div>
      <div className="message-note pb-3">
        <div className="message-note__header">
         {`From: ${message.from}`} 
        </div>
        <p className="message-note__note">
          {`${message.msg}`}
        </p>
      </div>
    </div>
  );
}

export default MessageNote;